<template>
  <div class="create-collect" :class="NFT_img">
    <b-container>
      <b-card bg-variant="light" text-variant="white">
        <div class="BlockChainDiv">
          <b-card-text class="text-p1">
            {{ $t("index.Create_Sell_p1") }}
          </b-card-text>
          <b-card-text class="text-p2">
            {{ $t("index.Create_Sell_p2") }}
          </b-card-text>
          <b-card-text class="text-p3">
            {{ $t("index.Create_Sell_p3") }}
          </b-card-text>
        </div>
        <!-- <b-button href="#" variant="primary">{{
            $t("index.Connect_Wallet")
          }}</b-button> -->
      </b-card>
    </b-container>
  </div>
</template>
<script>
import {
  BCard,
  BCardText,
  BButton,
  BContainer,
  BRow,
  BCol,
  BCardImg,
  BCardBody,
} from "bootstrap-vue";
export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BCardImg,
    BCardBody,
  },
  data() {
    return {
      NFT_img: "",
    };
  },
  methods: {
    checkLang() {
      if (localStorage.getItem("language") == "ar") {
        this.NFT_img = "create-collect-rtl";
      } else {
        this.NFT_img = "create-collect-ltr";
      }
    },
  },
  mounted() {
    this.checkLang();
  },
};
</script>
<style scoped>
.create-collect {
  margin-top: 40px;
  margin-bottom: 40px;
}

.create-collect .card .card-title {
  margin-bottom: 1.53rem;
  margin-top: 20px;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
/* .create-collect .card .card-text {

} */
.create-collect .card .btn-primary {
  margin-top: 35px;
  padding: 20px 15px;
  border-radius: 15px;
}
.create-collect .card .btn-primary:hover {
  transform: translateY(-5px);
}

@media (min-width: 720px) {
  .create-collect-ltr .card {
    background-image: url("../../assets/images/index/createandcollect.jpg");
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .create-collect-rtl .card {
    background-image: url("../../assets/images/index/createandcollect-rtl.jpg");
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .BlockChainDiv {
    margin-left: 20%;
    margin-top: 8%;
  }

  .text-p1 {
    color: #fff !important;
    font-weight: 700;
    font-size: 70px;
    line-height: 3rem;
  }
  .text-p2 {
    color: #fff !important;
    font-weight: 400;
    font-size: 25px;
  }
  .text-p3 {
    color: #fff !important;
    font-weight: 400;
    font-size: 25px;
    margin-left: 3.5rem;
  }
}
@media (max-width: 720px) {
  .create-collect-rtl .card,
  .create-collect-ltr .card {
    background-image: url("../../assets/images/index/createandcollect-small.jpg");
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .BlockChainDiv {
    margin-top: 15%;
    text-align: center;
  }

  .text-p1 {
    color: #fff !important;
    font-weight: 500;
    font-size: 30px;
    line-height: 1rem;
  }
  .text-p2 {
    color: #fff !important;
    font-weight: 400;
    font-size: 15px;
  }
  .text-p3 {
    color: #fff !important;
    font-weight: 400;
    font-size: 15px;
  }
}
</style>
