<template>
  <b-link class="cardCompany" :href="`/profile/${investor.username}`">
    <div class="logo">
      <img :src="investorImage(investor.investor.files)" :alt="investor.username" />
      <div class="ratings">
        <div class="item-rating">
          <ul class="unstyled-list list-inline">
            <li
              v-for="star in 5"
              :key="star"
              class="ratings-list-item"
              :class="{ 'ml-25': star - 1 }"
            >
              <feather-icon
                icon="StarIcon"
                size="16"
                :class="[
                  { 'fill-current': star <= investor.ratingNumber },
                  star <= investor.ratingNumber ? 'text-warning' : 'text-muted',
                ]"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info">
      <h3>{{ investor.investor.legalName }}</h3>
      <div class="type">
        <span class="">{{
          $store.state.locale.locale == "ar"
            ? investor.investor.investorCategory.arabicName
            : investor.investor.investorCategory.name
        }}</span>
      </div>
      <div class="location">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokewidth="{1.5}"
            stroke="currentColor"
            class="icon h-[15px] h-[15px]"
          >
            <path
              strokelinecap="round"
              strokelinejoin="round"
              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
            ></path>
            <path
              strokelinecap="round"
              strokelinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
            ></path>
          </svg>
          <span v-for="(data2, index) in investor.investor.address" :key="index" class="">
            {{ $store.state.locale.locale == "ar" ? data2.arabicName : data2.name }}
            <span v-if="index != Object.keys(investor.investor.address).length - 1">, </span>
          </span>
        </div>
      </div>
    </div>

    <div class="industries">
      <span v-for="(ind, index) in investor.investor.industries" :key="index">{{
        $store.state.locale.locale == "ar" ? "#" + ind.arabicName + " " : "#" + ind.name + " "
      }}</span>
    </div>

    <div class="meta">
      <ul>
        <li>
          <div class="label">{{ $t("investor.Investment_Size") }}</div>
          <div class="value">
            <span v-if="investor.investor.investmentSize.min">
              {{
                $store.state.locale.locale == "ar"
                  ? arCalcMonthlySales(
                      currencyExchange(
                        investor.investor.investmentSize.min,
                        investor.investor.investorCurrency.symbol
                      )
                    )
                  : calcMonthlySales(
                      currencyExchange(
                        investor.investor.investmentSize.min,
                        investor.investor.investorCurrency.symbol
                      )
                    )
              }}
              - </span
            ><span v-if="investor.investor.investmentSize.max">{{
              $store.state.locale.locale == "ar"
                ? arCalcMonthlySales(
                    currencyExchange(
                      investor.investor.investmentSize.max,
                      investor.investor.investorCurrency.symbol
                    )
                  )
                : calcMonthlySales(
                    currencyExchange(
                      investor.investor.investmentSize.max,
                      investor.investor.investorCurrency.symbol
                    )
                  )
            }}</span>

            <span v-if="investor.investor.investorCurrency">{{
              $store.state.myCurrency.myCurrency.symbol
            }}</span>
          </div>
        </li>
        <li>
          <div class="label">{{ $t("investor.Location") }}</div>
          <div class="value" v-if="investor.investor.interstedLocations[0] != undefined">
            <span class=""
              >{{
                $store.state.locale.locale == "ar"
                  ? "#" + investor.investor.interstedLocations[0].arabicName
                  : "#" + investor.investor.interstedLocations[0].name
              }}
            </span>
          </div>
        </li>
        <!-- <li>
          <div class="label">
            {{
              $store.state.locale.locale == "ar" &&
              company.company.companyCategory.arabicName !== undefined
                ? company.company.companyCategory.arabicName
                : company.company.companyCategory.name
            }}
          </div>
          <div class="value">
            <span v-if="company.company.price"
              >{{
                $store.state.locale.locale === "en"
                  ? calcMonthlySales(company.company.price)
                  : arCalcMonthlySales(company.company.price)
              }}
            </span>
            <span v-if="company.company.currency">{{
              company.company.currency.symbol
            }}</span>
          </div>
        </li> -->
      </ul>
    </div>

    <button class="btn btn-sm btn-primary">{{ $t("profile.Follow") }}</button>
  </b-link>
</template>
<script>
import { BLink } from "bootstrap-vue";
import millify from "millify";
export default {
  components: {
    BLink,
  },
  props: {
    investor: {
      type: Object,
      default: {},
    },
  },
  methods: {
    investorImage(data) {
      const logo = data.find((x) => {
        return x.name === "Profile Picture";
      });
      if (!logo) {
        return require("@/assets/images/index/investor.png");
      }
      return logo.fileUrl;

      // let imgInvestor = "";
      // for (let i = 0; i < data.length; i++) {
      //   if (data[i].name == "Profile Picture") {
      //     imgInvestor = data[i].fileUrl;
      //   }
      // }
      // if (imgInvestor == "") {
      //   imgInvestor = require("@/assets/images/index/investor.png");
      // }
      // return imgInvestor;
    },
    retrunCompanyTypeName(id) {
      let companyType = "";
      switch (id) {
        case 1:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Sole Proprietorship"
              : "مؤسسة فردية / تاجر وحيد";
          break;
        case 2:
          companyType =
            localStorage.getItem("language") === "en" ? "General Partnership" : "شراكة عامة";
          break;
        case 3:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Limited Liability Partnership (LLP)"
              : "شراكة ذات مسؤولية محدودة(ش.ذ.م.م)";
          break;
        case 4:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Limited Liability Company (LLC)"
              : "شركة ذات مسؤولية محدودة(ش.ذ.م.م)";
          break;
        case 5:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Private Limited Company"
              : "شركة خاصة محدودة ";
          break;
        case 6:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Public Limited Company"
              : "شركه عالميه محدوده";
          break;
        case 7:
          companyType =
            localStorage.getItem("language") === "en" ? "S Corporation" : "S Corporation";
          break;
        case 8:
          companyType =
            localStorage.getItem("language") === "en" ? "C Corporation" : "C Corporation";
          break;
        case 9:
          companyType = localStorage.getItem("language") === "en" ? "Other" : "غير ذلك";
          break;
        default:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Sole Proprietorship"
              : "مؤسسة فردية / تاجر وحيد";
      }
      return companyType;
    },
    calcMonthlySales(number) {
      return `${millify(number, {
        precision: 1,
        decimalSeparator: ".",
        space: true,
      })} `;
    },
    arCalcMonthlySales(number) {
      return `${millify(number, {
        precision: 1,
        units: ["", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ".",
        space: true,
      })} `;
    },
  },
};
</script>
<style scoped>
.cardCompany {
  display: block;
  background-color: #fff;
  border-radius: 0.375rem;
  padding: 0.75rem;
  height: 100%;
  border: 2px solid rgb(229, 231, 235);
  -webkit-transition: 0.3s linear all;
}
.cardCompany .logo {
  display: flex;
  height: 70px;
  align-content: center;
  padding: 0.75rem 0;
  padding-bottom: 0.5rem;
}
.cardCompany .logo img {
  margin-bottom: 0.5rem;
  display: block;
  height: 100%;
}
.cardCompany .logo .ratings {
  margin-left: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  color: rgb(251 146 60 / 1);
}
.cardCompany .info {
  /* margin: 0.5rem; */
  border-bottom: 1px solid rgb(243 244 246 / 1);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.cardCompany .info h3 {
  margin-bottom: 0px;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgb(55 65 81 / 1);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cardCompany .info .type {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgb(59 59 59);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cardCompany .info .location {
  display: flex;
  padding-top: 0.25rem;
  font-size: 0.9rem;
  line-height: 1rem;
  color: rgb(0 93 252);
  align-items: center;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cardCompany .info .location svg {
  margin-right: 1px;
  width: 20px;
  height: 20px;
  color: #0b2a7e;
}
.cardCompany .industries {
  display: flex;
  /* padding-left: 0.5rem; */
  padding-right: 0.5rem;
  overflow: hidden;
  position: relative;
}
.cardCompany .industries:after {
  content: " ";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 50px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
.cardCompany .industries span {
  margin-right: 0.25rem;
  display: block;
  cursor: pointer;
  background-color: rgb(243 244 246/1);
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  /* color: rgb(107 114 128/1); */
  white-space: nowrap;
  color: #2f2d7e;
  font-size: 13px;
}
.cardCompany .meta {
  margin: 0.5rem 0;
}
.cardCompany .meta ul {
  display: flex;
  padding-left: 0px;
  flex-direction: column;
  list-style: none;
}
.cardCompany .meta li {
  display: flex;
  border-bottom: 1px solid rgb(243 244 246 / 1);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.6rem;
  color: rgb(156 163 175 / 1);
  justify-content: space-between;
}
.cardCompany .meta li:last-child {
  border-bottom: none;
}
.cardCompany .meta li .label {
  width: 150px;
  color: rgb(107 114 128 / 1);
  font-size: 1rem;
}
.cardCompany .meta li .value {
  font-size: 0.83rem;
  font-weight: 500;
  color: #3e64b2;
}

.cardCompany .highlights {
  min-height: 50px;
}
.cardCompany .highlights span {
  color: #2a2a2a;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
