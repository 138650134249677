<template>
  <div class="swiper-navigations">
    <video
      autoplay
      muted
      loop
      id="myVideo"
      preload="metadata"
      playsinline
      poster="../../assets/images/placeholder.png"
      :src="srcVideo"
      type="video/mp4"
    ></video>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  data() {
    return {
      srcVideo: require("../../assets/images/slider.webm"),
      srcVideoAr: require("../../assets/images/slider.webm"),
      /* eslint-disable global-require */
      swiperData: [
        {
          img: require("../../../src/assets/images/sliderindex/sliders-01.jpg"),
        },
        {
          img: require("../../../src/assets/images/sliderindex/sliders-02.jpg"),
        },
        {
          img: require("../../../src/assets/images/sliderindex/sliders-03.jpg"),
        },
        {
          img: require("../../../src/assets/images/sliderindex/sliders-04.jpg"),
        },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        spaceBetween: 30,
        effect: "fade",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 50000,
          disableOnInteraction: false,
        },

        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },

  methods: {
    moveLet() {
      var TxtRotate = function (el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = "";
        this.tick();
        this.isDeleting = false;
      };

      TxtRotate.prototype.tick = function () {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];

        if (this.isDeleting) {
          this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
          this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

        var that = this;
        var delta = 300 - Math.random() * 100;

        if (this.isDeleting) {
          delta /= 2;
        }

        if (!this.isDeleting && this.txt === fullTxt) {
          delta = this.period;
          this.isDeleting = true;
        } else if (this.isDeleting && this.txt === "") {
          this.isDeleting = false;
          this.loopNum++;
          delta = 500;
        }

        setTimeout(function () {
          that.tick();
        }, delta);
      };

      window.onload = function () {
        var elements = document.getElementsByClassName("txt-rotate");
        for (var i = 0; i < elements.length; i++) {
          var toRotate = elements[i].getAttribute("data-rotate");
          var period = elements[i].getAttribute("data-period");
          if (toRotate) {
            new TxtRotate(elements[i], JSON.parse(toRotate), period);
          }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
        document.body.appendChild(css);
      };
    },
  },
  mounted() {
    this.moveLet();
  },
};
</script>

<style scoped>
.swiper-navigations {
  max-height: 750px;
  overflow: hidden;
}

.swp {
  background-image: url(../../../src/assets/images/sliderindex/sliders-01.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

[dir] .swiper1 {
  position: absolute;
  top: 30%;
  left: 10%;
}
.swiper1 h2 {
  font-size: 60px;
  color: #fff;
  font-family: auto;
}
[dir] .swiper2 {
  position: absolute;
  top: 35%;
  left: 10%;
}
.swiper2 h2 {
  font-size: 60px;
  color: #fff;
  font-family: auto;
}
[dir] .swiper3 {
  position: absolute;
  top: 30%;
  left: 10%;
}
.swiper3 h2 {
  font-size: 60px;
  color: #fff;
  font-family: auto;
}
[dir] .swiper4 {
  position: absolute;
  top: 35%;
  left: 10%;
}
.swiper4 h2 {
  font-size: 60px;
  color: #fff;
  font-family: auto;
}

@media (max-width: 750px) {
  .swiper-navigations {
    margin-top: 86px;
  }

  [dir] .swiper1 {
    position: absolute;
    top: 30%;
    left: 6%;
  }
  .swiper1 h2 {
    font-size: 18px;
    color: #fff;
    font-family: auto;
  }
  [dir] .swiper2 {
    position: absolute;
    top: 35%;
    left: 6%;
  }
  .swiper2 h2 {
    font-size: 18px;
    color: #fff;
    font-family: auto;
  }
  [dir] .swiper3 {
    position: absolute;
    top: 30%;
    left: 6%;
  }
  .swiper3 h2 {
    font-size: 18px;
    color: #fff;
    font-family: auto;
  }
  [dir] .swiper4 {
    position: absolute;
    top: 35%;
    left: 6%;
  }
  .swiper4 h2 {
    font-size: 18px;
    color: #fff;
    font-family: auto;
  }
}
</style>
<style>
video {
  background-size: cover;
  background-position: center center;
  object-fit: cover;
  object-fit: fill;
  height: 100%;
  width: 100%;
}
</style>
