<template>
  <b-container>
    <b-row>
      <h2 class="heading-topComany mb-0">
        {{ $t("index.Top Investor") }}
        <h6 class="view-all">
          <b-link :to="{ name: 'investor-list' }">{{ $t("index.View All") }} </b-link>
          <!-- <a href="/company/list"> {{ $t("index.View All") }}</a> -->
        </h6>
      </h2>
      <p class="pl-2">{{ $t("index.topInvestorsDetails") }}</p>
    </b-row>
    <b-row>
      <!-- <b-col md="3"> </b-col> -->
      <b-col :cols="12">
        <div v-if="!loading">
          <skeleton-list-top
            :loading="!loading"
            :item-number="4"
            :number-in-row="3"
            :height="'1500'"
          />
        </div>
        <div v-if="loading">
          <swiper class="swiper-responsive-breakpoints" :options="swiperOptions">
            <swiper-slide
              v-for="(data, index) in topinvestors"
              :key="index"
              :dir="$store.state.locale.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <investor-card :key="index" :investor="data" />
            </swiper-slide>
          </swiper>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import millify from "millify";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {
  BContainer,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
} from "bootstrap-vue";
import investorCard from "./cardList/InvestorCard.vue";
import SkeletonListTop from "../../views/nftdubai/components/SkeltonListTop.vue";

import "swiper/css/swiper.css";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    Swiper,
    SwiperSlide,
    investorCard,
    SkeletonListTop,
  },
  data() {
    return {
      loading: false,
      topinvestors: [],

      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  mounted() {
    axios.post("investors/top").then((response) => {
      this.topinvestors = response.data.data;
      this.loading = true;
    });
  },
  methods: {
    calcMonthlySales(number) {
      return `${millify(number, {
        precision: 3,
        decimalSeparator: ",",
        space: true,
      })} `;
    },
    arCalcMonthlySales(number) {
      return `${millify(number, {
        precision: 3,
        units: ["مئة", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ",",
        space: true,
      })} `;
    },
    investorImage(data) {
      let imgInvestor = "";
      for (let i = 0; i < data.length; i++) {
        if (data[i].name == "Profile Picture") {
          imgInvestor = data[i].fileUrl;
        }
      }
      if (imgInvestor == "") {
        imgInvestor = require("@/assets/images/index/investor.png");
      }
      return imgInvestor;
    },
    convert(value) {
      if (value >= 1000000000) {
        value = `${value / 1000000000}B`;
      } else if (value >= 1000000) {
        value = `${value / 1000000}M`;
      } else if (value >= 1000) {
        value = `${value / 1000}K`;
      }
      return value;
    },
  },
};
</script>

<style scoped>
.investor-name-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
}
.investor-name-img img {
  border-radius: 40px;
  margin-top: 5px;
}
.itemName {
  /* padding: 8px 0 0 10px; */
  color: #366ce3;
}
.heading-topComany {
  font-size: 25px;
  line-height: 48px;
  margin: 20px 15px;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topCompany .view-all a {
  font-size: 16px;
  font-weight: 600;
}

[dir] .card-body {
  padding: 0.5rem 1rem 0 1rem;
}

@media (min-width: 992px) {
  .footer-companyList {
    margin-left: -260px;
  }
}

.item-date-located {
  display: flex;
  justify-content: space-between;
}
.item-located-text {
  color: #000;
}
.locations {
  display: flex;
  /* background-color: #f5f5f5; */
  /* margin-bottom: 2px; */
}
.industries {
  display: flex;
  /* background-color: #f5f5f5; */
  /* padding: 2px; */
}
.item-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.item-social .fa-brands {
  margin-right: 10px;
}
.item-wrapper {
  margin-bottom: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.item-desc-img {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
}
.item-desc-img p {
  font-size: 12px;
}
.Interested-text {
  color: #000;
  font-size: 13px;
  font-weight: 500;
}
.Interested-value {
  color: #8f8f8f;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fa-location-dot {
  color: #516ccf;
}

.locations .locations-text,
.industries .industries-text {
  color: #000;
  font-size: 13px;
  font-weight: 500;
}
.locations .locations-value,
.industries .industries-value {
  color: #6e6b7b;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.locations p,
.industries p {
  /* padding: 5px 5px 0 5px; */
}

.investment-size .investment-size-text {
  font-weight: 500;
  text-align: center;
  color: #6e6b7b;
  font-size: 13px;
}
.investment-size .investment-size-value {
  color: #14357f;
  font-weight: 600;
  text-align: center;
  margin-top: -10px;
  font-size: 12px;
}

.product-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4b4b4b;
  font-weight: 600;
  font-size: 1.1rem;
}
.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #062455;
  font-weight: 500;
}
.industry-value {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6e6b7b;
}

.company-list-body {
  min-height: 900px;
}
@media (max-width: 700px) {
  .heading-topComany {
    font-size: 20px;
  }
}
.text-black {
  color: #000;
  font-weight: 500;
}
.text-darkblue {
  color: #062455;
  font-weight: 500;
}
</style>
