<template>
  <div class="mt-4 slider3Footer">
    <b-container>
      <b-row>
        <b-col :cols="12">
          <swiper
            class="swiper-navigations"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <!-- <swiper-slide v-for="(data, index) in swiperData" :key="index">
      <b-img :src="data.img" fluid />
    </swiper-slide> -->

            <swiper-slide>
              <div class="swiper1">
                <h2>
                  <b>{{ $t("index.slider1-p1") }}</b>
                </h2>
                <h2>{{ $t("index.slider1-p2") }}</h2>
                <h2>{{ $t("index.slider1-p3") }}</h2>
              </div>
              <b-img :src="swiperData[0].img" fluid />
            </swiper-slide>
            <swiper-slide>
              <div class="swiper2">
                <h2>
                  <b>{{ $t("index.slider2-p1") }}</b>
                </h2>
                <h2>{{ $t("index.slider2-p2") }}</h2>
              </div>
              <b-img :src="swiperData[1].img" fluid />
            </swiper-slide>
            <swiper-slide>
              <div class="swiper3">
                <h2>
                  <b>{{ $t("index.slider3-p1") }}</b>
                </h2>
                <h2 class="">{{ $t("index.slider3-p2") }}</h2>
                <h2 class="">{{ $t("index.slider3-p3") }}</h2>
              </div>
              <b-img :src="swiperData[2].img" fluid />
            </swiper-slide>
            <swiper-slide>
              <div class="swiper4">
                <h2>
                  <b>{{ $t("index.slider4-p1") }}</b>
                </h2>
                <h2 class="">{{ $t("index.slider4-p2") }}</h2>
                <h2 class="">{{ $t("index.slider4-p3") }}</h2>
              </div>
              <b-img :src="swiperData[3].img" fluid />
            </swiper-slide>

            <!-- Add Arrows -->
            <div slot="button-next" class="swiper-button-next" />
            <div slot="button-prev" class="swiper-button-prev" />
            <div slot="pagination" class="swiper-pagination" />
          </swiper>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg, BContainer, BRow, BCol } from "bootstrap-vue";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
      /* eslint-disable global-require */
      swiperData: [
        {
          img: require("../../../src/assets/images/sliderindex/sliders-00.jpg"),
        },
        {
          img: require("../../../src/assets/images/sliderindex/sliders-01.jpg"),
        },
        {
          img: require("../../../src/assets/images/sliderindex/sliders-02.jpg"),
        },
        {
          img: require("../../../src/assets/images/sliderindex/sliders-03.jpg"),
        },
        {
          img: require("../../../src/assets/images/sliderindex/sliders-04.jpg"),
        },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        spaceBetween: 30,
        effect: "fade",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },

        // pagination: {
        //   el: ".swiper-pagination",
        // },
      },
    };
  },

  methods: {
    moveLet() {
      var TxtRotate = function (el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = "";
        this.tick();
        this.isDeleting = false;
      };

      TxtRotate.prototype.tick = function () {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];

        if (this.isDeleting) {
          this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
          this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

        var that = this;
        var delta = 300 - Math.random() * 100;

        if (this.isDeleting) {
          delta /= 2;
        }

        if (!this.isDeleting && this.txt === fullTxt) {
          delta = this.period;
          this.isDeleting = true;
        } else if (this.isDeleting && this.txt === "") {
          this.isDeleting = false;
          this.loopNum++;
          delta = 500;
        }

        setTimeout(function () {
          that.tick();
        }, delta);
      };

      window.onload = function () {
        var elements = document.getElementsByClassName("txt-rotate");
        for (var i = 0; i < elements.length; i++) {
          var toRotate = elements[i].getAttribute("data-rotate");
          var period = elements[i].getAttribute("data-period");
          if (toRotate) {
            new TxtRotate(elements[i], JSON.parse(toRotate), period);
          }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML =
          ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
        document.body.appendChild(css);
      };
    },
  },
  mounted() {
    this.moveLet();
  },
};
</script>

<style scoped>
.swiper-navigations {
  max-height: 700px;
  overflow: hidden;
}

.swp {
  background-image: url(../../../src/assets/images/sliderindex/sliders-01.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

[dir] .swiper1 {
  position: absolute;
  top: 32%;
  left: 18%;
}
.swiper1 h2 {
  font-size: 40px;
  color: #fff;
}
[dir] .swiper2 {
  position: absolute;
  top: 42%;
  left: 10%;
}
.swiper2 h2 {
  font-size: 40px;
  color: #fff;
}
[dir] .swiper3 {
  position: absolute;
  top: 30%;
  left: 10%;
}
.swiper3 h2 {
  font-size: 40px;
  color: #fff;
}
[dir] .swiper4 {
  position: absolute;
  top: 35%;
  left: 10%;
}
.swiper4 h2 {
  font-size: 40px;
  color: #fff;
}
h2.blodtext {
  font-weight: 700;
  font-size: 50px;
}
.normalText {
  font-weight: 700;
  font-size: 50px;
}

@media (max-width: 750px) {
  .swiper-navigations {
    margin-top: 86px;
  }

  [dir] .swiper1 {
    position: absolute;
    top: 30%;
    left: 6%;
  }
  .swiper1 h2 {
    font-size: 18px;
    color: #fff;
  }
  [dir] .swiper2 {
    position: absolute;
    top: 35%;
    left: 6%;
  }
  .swiper2 h2 {
    font-size: 18px;
    color: #fff;
  }
  [dir] .swiper3 {
    position: absolute;
    top: 30%;
    left: 6%;
  }
  .swiper3 h2 {
    font-size: 18px;
    color: #fff;
  }
  [dir] .swiper4 {
    position: absolute;
    top: 35%;
    left: 6%;
  }
  .swiper4 h2 {
    font-size: 18px;
    color: #fff;
  }
}

@media (max-width: 750px) {
  .slider3Footer {
    display: none;
  }
}
</style>

<style scoped>
.img-fluid {
  height: 500px;
  width: 100%;
}

[dir] .swiper-pagination {
  display: none;
}
</style>
