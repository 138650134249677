<template>
  <div class="container my-5">
    <swiper
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide>
        <b-row>
          <b-col cols="12" md="8">
            <div class="footerSliderLeft">
              <h3 class="heading1">Engage in the revolution</h3>
              <h2 class="heading2">Let's DIGITALIZE your</h2>
              <h3 class="heading3">BUSINESSES</h3>
              <b-button to="/register" variant="primary"
                >Signup with Email or Phone Number</b-button
              >
            </div>
          </b-col>
          <b-col cols="12" md="4" class="d-none d-md-block"
            ><img class="w-100" :src="slides[0]"
          /></b-col>
        </b-row>
      </swiper-slide>

      <swiper-slide>
        <b-row>
          <b-col cols="12" md="8">
            <div class="footerSliderLeft">
              <h3 class="heading1">Engage in the revolution</h3>
              <h2 class="heading2">Let's DIGITALIZE your</h2>
              <h3 class="heading3">BUSINESSES</h3>
              <b-button to="/register" variant="primary"
                >Signup with Email or Phone Number</b-button
              >
            </div>
          </b-col>
          <b-col cols="12" md="4" class="d-none d-md-block"
            ><img class="w-100" :src="slides[1]"
          /></b-col>
        </b-row>
      </swiper-slide>

      <swiper-slide>
        <b-row>
          <b-col cols="12" md="8">
            <div class="footerSliderLeft">
              <h3 class="heading1">Engage in the revolution</h3>
              <h2 class="heading2">Let's DIGITALIZE your</h2>
              <h3 class="heading3">BUSINESSES</h3>
              <b-button to="/register" variant="primary"
                >Signup with Email or Phone Number</b-button
              >
            </div>
          </b-col>
          <b-col cols="12" md="4" class="d-none d-md-block"
            ><img class="w-100" :src="slides[2]"
          /></b-col>
        </b-row>
      </swiper-slide>

      <swiper-slide>
        <b-row>
          <b-col cols="12" md="8">
            <div class="footerSliderLeft">
              <h3 class="heading1">Engage in the revolution</h3>
              <h2 class="heading2">Let's DIGITALIZE your</h2>
              <h3 class="heading3">BUSINESSES</h3>
              <b-button to="/register" variant="primary"
                >Signup with Email or Phone Number</b-button
              >
            </div>
          </b-col>
          <b-col cols="12" md="4" class="d-none d-md-block"
            ><img class="w-100" :src="slides[3]"
          /></b-col>
        </b-row>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BRow, BCol, BButton } from "bootstrap-vue";
export default {
  name: "FooterSlider",
  data() {
    return {
      slides: [
        require("@/assets/footerslide/business.png"),
        require("@/assets/footerslide/ideas.png"),
        require("@/assets/footerslide/projects.png"),
        require("@/assets/footerslide/services.png"),
      ],
      swiperOptions: {
        spaceBetween: 30,
        effect: "fade",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    BRow,
    BCol,
    BButton,
  },
};
</script>
<style scoped>
.footerSliderLeft {
  padding: 100px;
}
.footerSliderLeft .heading1 {
  font-size: 4rem;
  font-weight: 600;
  color: #332983;
}
.footerSliderLeft .heading2 {
  font-size: 3rem;
  color: #49a9de;
  font-weight: bold;
}
.footerSliderLeft .heading3 {
  font-size: 4rem;
  font-weight: 600;
  color: #332983;
}
/* write media query for mobile */
@media (max-width: 768px) {
  .footerSliderLeft {
    padding: 50px 25px;
  }
  .footerSliderLeft .heading1 {
    font-size: 2rem;
  }
  .footerSliderLeft .heading2 {
    font-size: 2rem;
  }
  .footerSliderLeft .heading3 {
    font-size: 2rem;
  }
}
</style>
