<template>
  <div class="tradingview-widget-container">
    <div class="tradingview-widget-container__widget">
      <span class="td"></span>
    </div>
    <!-- <div class="tradingview-widget-copyright">
      <a
        href="https://www.tradingview.com/markets/"
        rel="noopener"
        target="_blank"
      >
        <span class="blue-text">Markets</span>
      </a>
    </div> -->
  </div>
</template>
<script>
export default {
  mounted() {
    const tradingViewScript = document.createElement("script");
    tradingViewScript.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    tradingViewScript.async = true;
    tradingViewScript.innerHTML = JSON.stringify({
      symbols: [
        {
          proName: "FOREXCOM:SPXUSD",
          title: "S&P 500",
        },
        {
          proName: "FOREXCOM:NSXUSD",
          title: "US 100",
        },
        {
          proName: "FX_IDC:EURUSD",
          title: "EUR/USD",
        },
        {
          proName: "BITSTAMP:BTCUSD",
          title: "Bitcoin",
        },
        {
          proName: "BITSTAMP:ETHUSD",
          title: "Ethereum",
        },
        {
          description: "POW",
          proName: "OKX:ETHWUSDC",
        },
      ],
      showSymbolLogo: true,
      colorTheme: "dark",
      isTransparent: false,
      displayMode: "regular",
      locale: localStorage.getItem("language") == "ar" ? "ar_AE" : "en",
    });
    // alert(tradingViewScript.innerHTML);
    document
      .getElementsByClassName("tradingview-widget-container__widget")[0]
      .appendChild(tradingViewScript);
  },
};
</script>

<style scoped></style>
<style>
.tradingview-widget-container__widget {
  pointer-events: none;
  display: flex;
  flex-direction: row-reverse;
}

.td {
  width: 2rem;
  height: 46px;
  background: #1e222d;
  border-top: 1px solid #434651;
  position: absolute;
  z-index: auto;
}
</style>
