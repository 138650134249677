<template>
  <div class="slider-new">
    <div class="container">
      <section id="slider">
        <input type="radio" name="slider" id="s1" :checked="currentSlide == 1" />
        <input type="radio" name="slider" id="s2" :checked="currentSlide == 2" />
        <input type="radio" name="slider" id="s3" :checked="currentSlide == 3" />
        <input type="radio" name="slider" id="s4" :checked="currentSlide == 4" />
        <input type="radio" name="slider" id="s5" :checked="currentSlide == 5" />

        <label for="s1" id="slide1"><img :src="slides[0]" /></label>
        <label for="s2" id="slide2"><img :src="slides[1]" /></label>
        <label for="s3" id="slide3"><img :src="slides[2]" /></label>
        <label for="s4" id="slide4"><img :src="slides[3]" /></label>
        <label for="s5" id="slide5"><img :src="slides[4]" /></label>
      </section>
    </div>
  </div>
</template>
<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide, Navigation } from "vue-awesome-swiper";
export default {
  components: {
    Swiper,
    SwiperSlide,
    Navigation,
  },
  data() {
    return {
      totalSlides: 5,
      currentSlide: 1,
      slides: [
        require("@/assets/slides/sliders_2-01.png"),
        require("@/assets/slides/sliders_2-02.png"),
        require("@/assets/slides/sliders_2-03.png"),
        require("@/assets/slides/sliders_2-05.png"),
        require("@/assets/slides/sliders_2-06.png"),
        require("@/assets/slides/sliders_2-07.png"),
      ],
    };
  },
  methods: {
    authoPlay() {
      if (this.currentSlide === this.totalSlides) {
        this.currentSlide = 1;
      } else {
        this.currentSlide++;
      }
    },
  },
  mounted() {
    setInterval(this.authoPlay, 5000);
  },
};
</script>
<style scoped>
.slider-new {
  background: rgb(248, 248, 255);
  background: linear-gradient(180deg, rgba(248, 248, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
  padding: 80px 0px 0px 0px;
}
.main-slider-image {
  border-radius: 50px;
}
.swiper-slide {
  text-align: center;
}

#slider {
  position: relative;
  width: 70%;
  height: 350px;
  margin: 20px auto;
  font-family: "Helvetica Neue", sans-serif;
  perspective: 1400px;
  transform-style: preserve-3d;
}

input[type="radio"] {
  position: relative;
  top: 108%;
  left: 50%;
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  opacity: 0.4;
  transform: translateX(-83px);
  cursor: pointer;
  display: none;
}

input[type="radio"]:nth-child(7) {
  margin-right: 0px;
}

input[type="radio"]:checked {
  opacity: 1;
}

#slider label {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: white;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 400ms ease;
  border-radius: 50px;
}
#slider label img {
  border-radius: 50px;
}

/* Slider Functionality */

/* Active Slide */
#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5 {
  transform: translate3d(0%, 0, 0px);
}

/* Next Slide */
#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide6,
#s6:checked ~ #slide7,
#s7:checked ~ #slide1 {
  transform: translate3d(15%, 0, -100px);
}
/* Next Slide */
#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide1 {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
  transform: translate3d(15%, 0, -100px);
}

/* Next to Next Slide */
#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  transform: translate3d(30%, 0, -250px);
}

/* Previous to Previous Slide */
#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  transform: translate3d(-30%, 0, -250px);
}

/* Previous Slide */
#s1:checked ~ #slide5,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4 {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
  transform: translate3d(-15%, 0, -100px);
}
</style>
