<template>
  <div>
    <b-container>
      <b-row>
        <h2 class="heading-newBlogs">
          {{ $t("index.Top Post") }}
          <h6 class="view-all">
            <a href="\blog\list"> {{ $t("index.View All") }}</a>
          </h6>
        </h2>
      </b-row>
      <!-- blogs -->
      <b-row class="blog-list-wrapper">
        <b-col v-for="blog in blogList2" :key="blog.img" md="4">
          <b-card tag="article" no-body>
            <b-row>
              <b-col class="mb-1">
                <b-link
                  class="mb-1"
                  :to="{ name: 'pages-blog-detail', params: { id: blog._id } }"
                >
                  <b-img
                    :src="blog.files.fileUrl"
                    :alt="blog.files.fileUrl"
                    class="card-img-top"
                    height="220"
                  />
                </b-link>
                <b-card-title class="px-1 mt-1">
                  <b-link
                    :to="{
                      name: 'pages-blog-detail',
                      params: { id: blog._id },
                    }"
                    class="text-body-heading"
                  >
                    {{ blog.title }}
                  </b-link>
                </b-card-title>
                <b-media-body class="px-1">
                  <small class="text-muted">{{
                    moment(blog.createdAt).format("LL [at] h:mm A z")
                  }}</small>
                </b-media-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!--/ blogs -->
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
  },
  data() {
    return {
      img: "https://res.cloudinary.com/dk7mx4do7/image/upload/v1658212016/postFiles/dabs87ozftlbrabbhuqy.jpg",

      blogList: [
        {
          id: 1,
          img: require("@/assets/images/blogs/blog-1.jpg"),
          title: "Features of NFT marketplace",
          avatar: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
          userFullName: "Ghani Pradita",
          blogPosted: "june 29, 2022",
          tags: ["NFT", "BlockChain"],
          excerpt:
            "The first site in the world to digitize businesses, companies, investors, and investors.",
          comment: 3,
        },
        {
          id: 2,
          img: require("@/assets/images/blogs/blog-2.png"),
          title: "NFT Dubai Holding Recruitment:(CV Shots)",
          avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
          userFullName: "Jorge Griffin",
          blogPosted: "june 29, 2022",
          tags: ["Recruitment"],
          excerpt:
            "The department handles recruitment in Africa, the Middle East.",
          comment: 6,
        },
        {
          id: 3,
          img: require("@/assets/images/blogs/blog-3.jpeg"),
          title: "How to Find an NFT You Love",
          avatar: require("@/assets/images/portrait/small/avatar-s-3.jpg"),
          userFullName: "Claudia Neal",
          blogPosted: "june 30, 2022",
          tags: ["NFT", "Project"],
          excerpt:
            "With so many projects out there, we know it can seem hard to find an NFT you love.",
          comment: 9,
        },
        // {
        //   id: 4,
        //   img: require("@/assets/images/blogs/blog-3.jpeg"),
        //   title: "How to Find an NFT You Love",
        //   avatar: require("@/assets/images/portrait/small/avatar-s-3.jpg"),
        //   userFullName: "Claudia Neal",
        //   blogPosted: "june 30, 2022",
        //   tags: ["NFT", "Project"],
        //   excerpt:
        //     "With so many projects out there, we know it can seem hard to find an NFT you love.",
        //   comment: 9,
        // },
      ],

      blogList2: [],
      search_query: "",

      //   currentPage: 1,
      //   perPage: 1,
      rows: 140,
    };
  },
  //   created() {
  //     this.$http.get("/blog/list/data").then((res) => {
  //       this.blogList = res.data;
  //     });
  //     this.$http.get("/blog/list/data/sidebar").then((res) => {
  //       this.blogSidebar = res.data;
  //     });
  //   },
  created() {},
  mounted() {
    // axios
    //   .get("posts/allBlogs/")
    //   .then((response) => (this.blogList2 = response.data.data));
    this.getBlogs();
  },
  methods: {
    kFormatter,
    moment: function (input) {
      return moment(input);
    },
    strippedContent: function (string) {
      string = string.replace(new RegExp("<[^>]*>", "g"), "");
      return string.replace(new RegExp("&nbsp;", "g"), " ");
    },

    tagsColor(tag) {
      if (tag === "Quote") return "light-info";
      if (tag === "Gaming") return "light-danger";
      if (tag === "Fashion") return "light-primary";
      if (tag === "Video") return "light-warning";
      if (tag === "Food") return "light-success";
      return "light-primary";
    },
    getBlogs() {
      const lang = localStorage.getItem("language") || "en";

      const formData = new FormData();
      // formData.append("q", searchQuery.value);
      // formData.append("page", currentPage.value);
      formData.append("perPage", 3);
      formData.append("language", lang);
      formData.append("type", "blog");

      axios
        .post("posts/search/", formData)
        .then((response) => (this.blogList2 = response.data.data));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style scoped>
.heading-newBlogs {
  font-size: 25px;
  line-height: 48px;
  margin: 32px 0;
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  padding-bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view-all a {
  /* font-size: 16px;
  font-weight: 600; */
}
.blogs-page {
  margin-top: 110px;
  margin-bottom: 110px;
}

.blogs-page .media-body {
  display: contents;
}
.blogs-page .media {
  margin-bottom: 10px;
}
.blogs-page .card-img-top {
  max-height: 245px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-item.active .page-link {
  background-color: #366ce3;
}
@media (min-width: 920px) {
  .card {
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 700px) {
  .heading-newBlogs {
    font-size: 20px;
  }
}
[dir] .card .card-title {
  /* margin-bottom: 1.53rem; */
  /* min-height: 43px; */
}

.blog-list-wrapper .blog-content-truncate {
  -webkit-line-clamp: 3;
}
.blog-list-wrapper .blog-title-truncate {
  -webkit-line-clamp: 1;
}

[dir] .card-img,
[dir] .card-img-top {
  border-top-left-radius: calc(0.428rem - 1px);
  border-bottom-left-radius: calc(0.428rem - 1px);
  border-top-right-radius: 0;
}
</style>
