<template>
  <b-container>
    <b-row>
      <h2 class="heading-topComany mb-0">
        {{ $t("index.Top Freelancer") }}

        <h6 class="view-all">
          <b-link :to="{ name: 'freelancer-list' }">{{ $t("index.View All") }} </b-link>
          <!-- <a href="/company/list"> {{ $t("index.View All") }}</a> -->
        </h6>
      </h2>
      <p class="pl-2">{{ $t("index.topFreelancersDetails") }}</p>
    </b-row>
    <b-row>
      <!-- <b-col md="3"> </b-col> -->
      <b-col :cols="12">
        <div v-if="!loading">
          <skeleton-list-top
            :loading="!loading"
            :item-number="4"
            :number-in-row="3"
            :height="'1500'"
          />
        </div>
        <div v-if="loading">
          <swiper class="swiper-responsive-breakpoints" :options="swiperOptions">
            <swiper-slide
              v-for="(data, index) in topFreelancers"
              :key="index"
              :dir="$store.state.locale.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <freelancer-card :key="index" :freelancer="data" />
            </swiper-slide>
          </swiper>
        </div>
      </b-col>
    </b-row>

    <b-row />
  </b-container>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {
  BContainer,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
} from "bootstrap-vue";
import freelancerCard from "./cardList/FreelancerCard.vue";
import SkeletonListTop from "../../views/nftdubai/components/SkeltonListTop.vue";

import "swiper/css/swiper.css";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    Swiper,
    SwiperSlide,
    freelancerCard,
    SkeletonListTop,
  },
  data() {
    return {
      loading: false,
      topFreelancers: [],

      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },

  mounted() {
    // this.fetchCountries;
    axios.post("freelancers/top").then((response) => {
      this.topFreelancers = response.data.data;
      this.loading = true;
    });
  },

  methods: {
    freelancerImage(data) {
      let imgfreelancer = "";
      for (let i = 0; i < data.length; i++) {
        if (data[i].name == "Personal Photo") {
          imgfreelancer = data[i].fileUrl;
        }
      }
      if (imgfreelancer == "") {
        imgfreelancer = require("@/assets/images/index/freelancer.png");
      }

      return imgfreelancer;
    },
  },
};
</script>

<style scoped>
.heading-topComany {
  font-size: 25px;
  margin: 20px 15px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topCompany .view-all a {
  font-size: 16px;
  font-weight: 600;
}

[dir] .card-body {
  padding: 0.5rem 1rem 0 1rem;
}

@media (min-width: 992px) {
  .footer-companyList {
    margin-left: -260px;
  }
}

.item-date-located {
  display: flex;
  justify-content: space-between;
}

.item-wrapper {
  margin-bottom: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.fa-location-dot {
  color: #516ccf;
}

.item-legalEntity {
  color: #6e6b7b;
  font-size: 12px;
}
.company-list-body {
  min-height: 900px;
}

.card-header-freelancer {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.freelancer-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4b4b4b;
  font-weight: 600;
}
.item-desc-img {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  min-height: 78px;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 500;
  font-size: 13px;
  color: #062455;
}
.skills-value {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6e6b7b;
  font-size: 12px;
}
.industry-value {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6e6b7b;
  font-size: 12px;
}
.item-footer {
  margin-bottom: 15px;
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
}
.sad {
  color: #1476b9;
  font-weight: 600;
}
@media (max-width: 700px) {
  .heading-topComany {
    font-size: 20px;
  }
}
.text-black {
  color: #000;
  font-weight: 500;
}
.skills-text,
.industry-text {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

.text-darkblue {
  color: #062455;
  font-weight: 500;
}

.card-header-freelancer .card-top-img img {
  margin-left: 12px;
  margin-top: 5px;

  width: 60px;
  height: 60px;
  border-radius: 40px;
  margin-right: 15px;
}
.item-located-text {
  color: #000;
}
</style>
