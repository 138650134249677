import { render, staticRenderFns } from "./Trading2.vue?vue&type=template&id=cbad1124&scoped=true&"
import script from "./Trading2.vue?vue&type=script&lang=js&"
export * from "./Trading2.vue?vue&type=script&lang=js&"
import style1 from "./Trading2.vue?vue&type=style&index=1&id=cbad1124&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbad1124",
  null
  
)

export default component.exports