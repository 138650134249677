<template>
  <div class="slider-new">
    <div class="container">
      <swiper :options="sliderOptions" :modules="modules">
        <swiper-slide v-for="(slide, key) in slides" :key="`homeMainSlide${key}`"
          ><img class="main-slider-image" :src="slide" alt=""
        /></swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide, Navigation } from "vue-awesome-swiper";
export default {
  components: {
    Swiper,
    SwiperSlide,
    Navigation,
  },
  data() {
    return {
      slides: [
        require("@/assets/slides/sliders-01.jpg"),
        require("@/assets/slides/sliders-02.jpg"),
        require("@/assets/slides/sliders-03.jpg"),
        require("@/assets/slides/sliders-04.jpg"),
        require("@/assets/slides/sliders-05.jpg"),
        require("@/assets/slides/sliders-06.jpg"),
      ],
      sliderOptions: {
        slidesPerView: 1,
        spaceBetween: 50,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
      },
    };
  },
};
</script>
<style scoped>
.slider-new {
  background: rgb(248, 248, 255);
  background: linear-gradient(180deg, rgba(248, 248, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
  padding: 100px 0px;
}
.main-slider-image {
  border-radius: 50px;
}
.swiper-slide {
  text-align: center;
}
</style>
