<template>
  <b-container>
    <b-row>
      <h2 class="heading-topComany mb-0">
        {{ $t("index.Top Company") }}
        <h6 class="view-all">
          <b-link :to="{ name: 'company-list' }"
            >{{ $t("index.View All") }}
          </b-link>
          <!-- <a href="/company/list"> {{ $t("index.View All") }}</a> -->
        </h6>
      </h2>
      <p class="pl-2">{{ $t("index.topCompaniesDetails") }}</p>
    </b-row>
    <b-row>
      <!-- <b-col :cols="3">
        <div>
          <img :src="topImage" alt="" />
        </div>
      </b-col> -->
      <b-col :cols="12">
        <div v-if="!loading">
          <skeleton-list-top
            :loading="!loading"
            :item-number="4"
            :number-in-row="3"
            :height="'1500'"
          />
        </div>
        <div v-if="loading">
          <swiper
            class="swiper-responsive-breakpoints"
            :options="swiperOptions"
          >
            <swiper-slide
              v-for="(data, index) in topCompanies"
              :key="index"
              :dir="$store.state.locale.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <company-card :company="data" :key="index" />
            </swiper-slide>
          </swiper>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BContainer, BRow, BCol, BLink } from "bootstrap-vue";
import "swiper/css/swiper.css";
import CompanyCard2 from "./company/CompanyCard2.vue";
import CompanyCard from "../../views/nftdubai/components/CompanyCard.vue";
import SkeletonListTop from "../../views/nftdubai/components/SkeltonListTop.vue";

export default {
  components: {
    BContainer,
    BCol,
    BRow,
    Swiper,
    SwiperSlide,
    CompanyCard,
    SkeletonListTop,
    BLink,
  },
  data() {
    return {
      loading: false,
      topImage: require("@/assets/images/index/company.png"),
      topCompanies: [],
      topCompaniesStates: [],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 50,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  mounted() {
    axios.post("companies/top").then((response) => {
      this.topCompanies = response.data;
      this.getTopCompanies(this.topCompanies);
      // console.log(this.topCompanies);
      this.loading = true;
    });
  },
  methods: {
    companyImage(data) {
      const logo = data.find((x) => {
        return x.name === "company Image";
      });

      let imgCompany = "";
      for (let i = 0; i < data.length; i++) {
        if (data[i].name == "company Image") {
          imgCompany = data[i].fileUrl;
        }
      }
      if (imgCompany == "") {
        imgCompany = require("@/assets/images/index/company.png");
      }
      return imgCompany;
    },
    formatedTopCompanies(data) {
      // console.log(data);
      // console.log(JSON.parse(this.topCompanies.company.address.state));
      const topCom = [];
      data.forEach((element) => {
        topCom.push({
          state: JSON.parse(element.company.address.state),
        });
      });
      this.topCompaniesStates = topCom;
      // console.log(this.topCompaniesStates);
    },
    getTopCompanies(state) {
      const formatCompanies = [];
      state.forEach((element) => {
        const stateObj = JSON.parse(element.company.address.state);
        formatCompanies.push({
          // eslint-disable-next-line no-underscore-dangle
          id: element._id,
          name: element.company.name,
          establishDate: element.company.establishDate,
          description: element.company.description,
          highlights: element.company.highlights,
          industries: element.company.industries,
          country:
            this.$store.state.locale.locale == "ar"
              ? element.company.address.country.arabicName
              : element.company.address.country.name,
          state:
            this.$store.state.locale.locale == "ar"
              ? stateObj.arabicName != undefined
                ? stateObj.arabicName
                : stateObj.name
              : stateObj.name,
          city: element.company.address.city,
          avgMonthlySales: element.company.avgMonthlySales,
          ebitda: element.company.ebitda,
          companyType: element.company.companyType,
          companyCategory:
            this.$store.state.locale.locale == "ar"
              ? element.company.companyCategory.arabicName
              : element.company.companyCategory.name,
          currency: element.company.currency
            ? element.company.currency.symbol
            : "$",
          // currency:
          //   element.company && element.company.currency
          //     ? element.company.currency.symbol
          //     : store.state.locale.locale == "ar"
          //     ? "مزاد"
          //     : "Auction",
          price:
            element.company && element.company.price
              ? element.company.price
              : 1,
          username: element.username,
          ratingNumber: element.ratingNumber,
          companyImg:
            element.company.files.length > 0 ? element.company.files : null,
        });
      });
      this.topCompanies = formatCompanies;
      return formatCompanies;
    },
  },
};
</script>
<style scoped>
.bg-info {
  background-color: transparent !important;
}
.heading-topComany {
  font-size: 25px;
  line-height: 48px;

  width: 100%;
  margin: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topCompany .view-all a {
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 992px) {
  .footer-companyList {
    margin-left: -260px;
  }
}

@media (max-width: 700px) {
  .heading-topComany {
    font-size: 20px;
  }
}
</style>
